<template lang='pug'>
  extends ../templates/PageBase.pug
  block content
    .container-fluid.container-fixed-lg
      // START ROW
      .row
        .d-flex.flex-column(v-for='item in items' :class='[`col-lg-${item.lg}`, `col-sm-${item.sm}`]')
          // START ITEM
          .card.social-card.share.full-width.m-b-10.no-border(data-social='item')
            .card-header.clearfix
              h5.text-success.pull-left.fs-12
                | Tools
              .clearfix
            .card-description
              h5.hint-text.no-margin {{ item.title }}
              h5.small.hint-text.no-margin {{ item.description }}
            .card-footer.clearfix
              .pull-left
                span.text-success
                  router-link(:to='item.route') Go to {{ item.title }}
              .clearfix

</template>

<script>
  // @ is an alias to /src
  const items = [
    {lg: 3, sm: 6, route: '/password-generator', title: 'Password Generator', description: 'Create quick and easy, strong and save Passwords online'},
    {lg: 3, sm: 6, route: '/json-validator-formatter', title: 'JSON Format, Validator & Path', description: 'Filter, format, search and transform large JSON-Objects'},
    {lg: 3, sm: 6, route: '/json-to-yaml-2-json', title: 'JSON to YAML 2 JSON', description: 'Convert JSON to YAML and Back with live-editing'},
    {lg: 3, sm: 6, route: '/save-code-with-highlighting-on-evernote', title: 'Evernote Code highlighting', description: 'Save Code-Snippets which works on all Evernote clients'},
  ]

  export default {
    name: 'home',
    components: {},
    mounted() {
    },
    data() {
      return {
        items: items
      }
    }
  }
</script>

<style scoped>
  .gallery {
    margin-top: 40px;
  }
</style>
